<template>
  <div>
    <v-chip
      v-if="estadoCliente != CLIENTE.estados.activo"
      dark
      label
      small
      :color="CLIENTE.colores[CLIENTE.idestados[estadoCliente]]"
      text-color="white"
      class="ml-1 mt-1"
    >
      Cliente {{ $options.filters.lowercase(CLIENTE.descripcionEstado[CLIENTE.idestados[estadoCliente]]) }}
    </v-chip>
    <v-chip
      v-if="estadoSistema != SISTEMA.estados.activo"
      dark
      label
      small
      :color="SISTEMA.colores[SISTEMA.idestados[estadoSistema]]"
      text-color="white"
      class="ml-1 mt-1"
    >
      Sistema {{ SISTEMA.descripcionEstado[SISTEMA.idestados[estadoSistema]] }}
    </v-chip>
  </div>
</template>

<script>
import { CLIENTE, SISTEMA } from '@/utils/consts'

export default {
  props: {
    estadoCliente:{
      type: Number,
      default: 1,
    },
    estadoSistema:{
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      CLIENTE,
      SISTEMA,
    }
  },
}
</script>
